var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { staticClass: "edit-comment-page", attrs: { "visible": _vm.visible, "title": "Edit Comment Maintenance", "width": 832, "after-close": _vm.afterModalClose }, on: { "ok": _vm.updateComment, "cancel": _vm.closeModal } }, [_c("a-icon", { staticClass: "minimize-icon", attrs: { "type": "minus" }, on: { "click": _vm.hideModal } }), _c("a-spin", { attrs: { "spinning": _vm.isFetchData } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("div", { staticClass: "header-title" }, [_vm._v("Item - " + _vm._s(_vm.entity.item))]), _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.entity.userName))])]), _c("a-col", { attrs: { "span": 12 } }, [_c("div", { staticClass: "header-title" }, [_vm._v("Estimated Cuts")]), _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.entity.estimatedCuts || 0))])])], 1), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("div", [_c("label", [_vm._v("Start Date")]), _c("a-date-picker", { attrs: { "disabled-date": _vm.disabledStartDate, "value": _vm.detailComment.startDate }, on: { "change": function($event) {
    return _vm.onDateChange("startDate", $event);
  } } })], 1)]), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6 } }, [_c("div", [_c("label", [_vm._v("End Date")]), _c("a-date-picker", { attrs: { "disabled-date": _vm.disabledEndDate, "value": _vm.detailComment.endDate }, on: { "change": function($event) {
    return _vm.onDateChange("endDate", $event);
  } } })], 1)]), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Facility", "disabled": "", "value": _vm.detailComment.facility } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Reason Code", "data-source": _vm.listReasonCodes, "source": "reasonCode", "option-label-prop": "description", "value": _vm.detailComment.reasonID }, on: { "change": function($event) {
    return _vm.updateEditForm("reasonCode", $event);
  } }, scopedSlots: _vm._u([{ key: "data-source", fn: function() {
    return [_c("a-select-option", { attrs: { "value": "label", "disabled": "" } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 20 } }, [_vm._v(" Description ")]), _c("a-col", { staticStyle: { "font-weight": "bold", "color": "#272d35" }, attrs: { "span": 4 } }, [_vm._v(" Include ")])], 1)], 1), _vm._l(_vm.listReasonCodes, function(code) {
      return _c("a-select-option", { key: code.reasonCode, attrs: { "value": code.reasonCode, "description": code.reasonCodeDesc } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 20 } }, [_vm._v(" " + _vm._s(code.reasonCode) + " - " + _vm._s(code.reasonCodeDesc) + " ")]), _c("a-col", { attrs: { "span": 4 } }, [_vm._v(_vm._s(code.includeinCaseFill))])], 1)], 1);
    })];
  }, proxy: true }]) })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Who", "value": _vm.detailComment.actionWho }, on: { "change": function($event) {
    return _vm.updateEditForm("actionWho", $event);
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Root Cause", "value": _vm.detailComment.rootCause }, on: { "change": function($event) {
    return _vm.updateEditForm("rootCause", $event);
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-area", { attrs: { "label": "Action Taken", "value": _vm.detailComment.actionRequired }, on: { "change": function($event) {
    return _vm.updateEditForm("actionRequired", $event);
  } } })], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var EditCommentMaintenance_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "EditCommentMaintenance",
  props: {
    country: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
      required: true
    }
  },
  inject: ["resourceProps"],
  data() {
    const [commentProps, reasonCodeProps] = this.resourceProps;
    const apiUrl2 = commentProps.apiUrl;
    return {
      apiUrl: apiUrl2,
      commentProps,
      reasonCodeProps,
      detailCommentUrl: `${apiUrl2}/cfr/phanned-future-cut-comments/${this.itemId}?country=${this.country}`,
      detailComment: {},
      visible: false,
      isFetchData: false,
      isMinimize: false
    };
  },
  computed: {
    entity() {
      return this.commentProps.crud.getEntity() || {};
    },
    listReasonCodes() {
      return this.reasonCodeProps.crud.getList();
    }
  },
  watch: {
    entity(newVal) {
      if (Object.keys(newVal).length > 0) {
        const {
          actionRequired,
          actionWho,
          endDate,
          estimatedCuts,
          facility,
          item,
          oosComment,
          startDate,
          reasonID,
          rootCause
        } = newVal;
        this.updateEditForm("actionRequired", actionRequired);
        this.updateEditForm("actionWho", actionWho);
        this.updateEditForm("endDate", endDate);
        this.updateEditForm("estimatedCuts", estimatedCuts);
        this.updateEditForm("facility", facility);
        this.updateEditForm("item", item);
        this.updateEditForm("oosComment", oosComment);
        this.updateEditForm("startDate", startDate);
        this.updateEditForm("sDateOrig", startDate);
        this.updateEditForm("reasonID", reasonID);
        this.updateEditForm("rootCause", rootCause);
        this.isFetchData = false;
      }
    }
  },
  mounted() {
    this.visible = true;
    this.isFetchData = true;
    this.commentProps.crud.fetchEntity(this.detailCommentUrl);
    this.fetchReasonCodeList();
    this.updateEditForm("id", this.itemId);
  },
  methods: {
    afterModalClose() {
      if (!this.isMinimize)
        this.$router.push(this.commentProps.redirectRoute);
    },
    closeModal() {
      this.visible = false;
      this.$router.push(this.commentProps.redirectRoute);
    },
    disabledStartDate(current) {
      return current && current < this.$moment().subtract(6, "w");
    },
    disabledEndDate(current) {
      return current && current < this.$moment(this.detailComment.startDate).startOf("day");
    },
    fetchCommentList() {
      this.commentProps.crud.setQueryString("country", this.country);
      this.commentProps.crud.fetchList();
    },
    fetchReasonCodeList() {
      this.reasonCodeProps.crud.fetchList();
    },
    generateLabel(option, arrKeyDisplay) {
      let str = "";
      arrKeyDisplay.map((keyItem, keyIndex) => {
        if (keyIndex !== 0)
          str += option[keyItem] ? ` - ${option[keyItem].trim()}` : "";
        else
          str += option[keyItem].trim();
      });
      return str;
    },
    hideModal() {
      this.isMinimize = !this.isMinimize;
      this.visible = !this.visible;
      this.$notification.open({
        key: "minimize",
        message: "Editing Comment",
        description: "Click to open",
        duration: null,
        onClick: () => this.onNotificationClick(),
        onClose: () => {
          this.isMinimize = !this.isMinimize;
          this.afterModalClose();
        }
      });
    },
    onNotificationClick() {
      this.visible = !this.visible;
      this.isMinimize = !this.isMinimize;
      this.$notification.close("minimize");
    },
    onDateChange(key, date) {
      if (key === "startDate") {
        this.updateEditForm("endDate", null);
      }
      this.updateEditForm(key, date.format("YYYY-MM-DD"));
    },
    parseReasonCodeLabel(keyDisplay) {
      return (option) => option[keyDisplay] !== "" ? this.generateLabel(option, keyDisplay) : "All";
    },
    updateComment() {
      this.commentProps.crud.submitEntity("replace", `${this.apiUrl}/cfr/phanned-future-cut-comments?country=${this.country}`).then(() => {
        this.commentProps.crud.clearEntity("editForm");
        this.fetchCommentList();
        this.closeModal();
      });
    },
    updateEditForm(resourceKey, value = "") {
      this.detailComment = __spreadProps(__spreadValues({}, this.detailComment), {
        [resourceKey]: value
      });
      this.commentProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "59e80953", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditCommentMaintenance = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", { attrs: { "name": ["cfr.phanned-future-cut-comments", "cfr.common.reason-codes"], "api-url": _vm.apiUrl, "redirect-route": ["/cfr/cut-reason-comment"] } }, [_c("edit-comment-maintenance", { attrs: { "item-id": _vm.itemId, "country": _vm.country } })], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    "edit-comment-maintenance": EditCommentMaintenance
  },
  props: {
    itemId: {
      type: Number,
      required: true
    },
    country: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
